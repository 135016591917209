"use client";

import { Providers } from "./provider";
import "../styles/globals.css";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Script from "next/script";
import {
    Source_Serif_4,
    Source_Sans_3,
    Source_Code_Pro,
} from "next/font/google";
import { GeistSans } from "geist/font/sans";
import { GeistMono } from "geist/font/mono";

const sourceSerif4 = Source_Serif_4({
    subsets: ["latin"],
});
const sourceSans3 = Source_Sans_3({
    subsets: ["latin"],
});
const sourceCodePro = Source_Code_Pro({
    subsets: ["latin"],
});

export default function RootLayout({ children }) {
    return (
        <html lang="fr">
            <Script
                id="clarity-script"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `
              (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "l8eed3kn0y");
            `,
                }}
            />
            <Script
                id="google-tag-manager"
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-ZER1R4HYDJ"
            />
            <Script
                id="google-tag-manager-config"
                dangerouslySetInnerHTML={{
                    __html: "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-ZER1R4HYDJ');gtag('config', 'AW-10852173180')",
                }}
            />
            <Script
                async
                id="metricool"
                dangerouslySetInnerHTML={{
                    __html: `function loadScript(a){var b=document.getElementsByTagName("head")[0],c=document.createElement("script");c.type="text/javascript",c.src="https://tracker.metricool.com/resources/be.js",c.onreadystatechange=a,c.onload=a,b.appendChild(c)}loadScript(function(){beTracker.t({hash:"f5e7802586ae71f3a6fdc1aaa686a6a8"})});`,
                }}
            />
            <Script
                async
                id="active-campaign"
                dangerouslySetInnerHTML={{
                    __html: `(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
    vgo('setAccount', '802133771');
    vgo('setTrackByDefault', true);

    vgo('process');`,
                }}
            />
            <style jsx global>{`
                :root {
                    --source-serif-font: ${sourceSerif4.style.fontFamily};
                    --source-sans-font: ${sourceSans3.style.fontFamily};
                    --source-code-font: ${sourceCodePro.style.fontFamily};
                    --font-geist-sans: ${GeistSans.style.fontFamily};
                    --font-geist-mono: ${GeistMono.style.fontFamily};
                }
            `}</style>
            <body>
                <Providers>
                    <ProgressBar color="#E8511D" />
                    <ToastContainer
                        position="bottom-right"
                        autoClose={8000}
                        theme="dark"
                    />
                    {children}
                </Providers>
            </body>
        </html>
    );
}
